import axios from 'axios';
import { API_ENDPOINTS, customToast, route, Variants } from 'carrier-fe';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function RegisterId() {
	const { registrationId } = useParams();
	const navigate = useNavigate();

	const [stage, setStage] = useState('');
	const [isFetching, setIsFetching] = useState(false);

	useEffect(() => {
		if (!registrationId || isFetching) return;
		setIsFetching(true);

		axios
			.get(
				route(API_ENDPOINTS.REGISTRATION.VIEW, {
					registrationId: registrationId || '',
				})
			)
			.then((res) => setStage(res.data.data.stage))
			.catch((err) =>
				customToast({
					variant: Variants.Danger,
					title:
						err?.response?.data?.message ||
						'Something went wrong...',
				})
			)
			.finally(() => setIsFetching(false));
	}, [registrationId]);

	useEffect(() => {
		if (stage && stage === '') return;

		switch (stage) {
			case 'step-one':
				return navigate('/register/' + registrationId + '/overview');
			case 'step-two':
				return navigate(
					'/register/' + registrationId + '/system-selection'
				);
			case 'step-three':
				return navigate(
					'/register/' + registrationId + '/system-specific'
				);
			case 'step-four':
				return navigate(
					'/register/' + registrationId + '/user-details'
				);
			case 'step-five':
				return navigate(
					'/register/' + registrationId + '/company-details'
				);
			case 'step-six':
				return navigate('/register/' + registrationId + '/complete');
		}
	}, [stage]);

	return <></>;
}

export default RegisterId;
