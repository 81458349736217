import {
	useForm,
	route,
	API_ENDPOINTS,
	customToast,
	Variants,
	PostcodeLookup,
	useLangContext,
	getThemeFromUrl,
	StringHelpers,
	PasswordFieldAndRules,
	useDisabledContext,
	Button,
	ButtonTypes,
	SubTitle,
	PhoneNumberField,
	GasSafeLookup,
	ContentContainer,
	AppContainer,
	GasSafeCompanyResponse,
	GasSafeEngineerResponse,
} from 'carrier-fe';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';


const defaultData = {
	first_name: '',
	last_name: '',
	email: '',
	username: '',
	landline_phone_prefix: '',
	landline_phone_number: '',
	mobile_phone_prefix: '',
	mobile_phone_number: '',
	password: '',
	password_confirmation: '',
	terms_confirmation: '0',
	address_id: '',
	address_line_1: '',
	address_line_2: '',
	address_line_3: '',
	town_city: '',
	state_county: '',
	postcode_zipcode: '',
	company_gas_safe_number_skipped: '0',
	sub_country_name: '',
	country_code_iso_3: '',
	gas_safe_licence_card_number: '',
	gas_safe_confirmation: '0',
	gas_safe_licence_card_number_skipped: '1',
};

type response = { data: typeof defaultData };

function UserDetails() {
	const { registrationId } = useParams();
	const { registration, crud, fields } = useLangContext();
	const { disabled } = useDisabledContext();
	const navigate = useNavigate();
	const [skipStepThree, setSkipStepThree] = useState<boolean>(false);
	const [hasGasBoiler, setHasGasBoiler] = useState<boolean>(false);
	const [gasSafeNameMismatch, setGasSafeNameMismatch] = useState('')

	const termsLink = useMemo(() => {
		switch (getThemeFromUrl()) {
			case 'toshiba':
				return 'https://www.toshiba.eu/about-toshiba-europe/terms-and-conditions/';
			case 'vokera':
				return 'https://www.vokera.co.uk/warranty-tcs/';
			case 'viessmann':
			default:
				return '/VPlus_Terms_and_Conditions2(1).pdf';
		}
	}, []);

	const lang = registration?.page?.step_four[getThemeFromUrl()] || null;

	const patch = async (data: typeof defaultData) => {
		handleGasSafeLookupChange(data)
		const res = await axios.patch(
			route(API_ENDPOINTS.REGISTRATION.STEP_FOUR, {
				registrationId: registrationId || '',
			}),
			data
		);
		return res;
	};

	const { Submit, Input, setValues, errors, store, isSuccess } = useForm(
		patch,
		defaultData,
		{ silentSuccess: true }
	);

	useEffect(() => {
		axios
			.get(
				route(API_ENDPOINTS.REGISTRATION.STEP_TWO, {
					registrationId: registrationId || '',
				})
			)
			.then((res) => {
				const {
					company_gas_boiler_systems_enabled,
					company_heat_pump_systems_enabled,
					company_solar_systems_enabled,
				} = res.data.data;

				setHasGasBoiler(company_gas_boiler_systems_enabled === '1');

				if (
					company_gas_boiler_systems_enabled === '0' &&
					company_solar_systems_enabled === '0' &&
					company_heat_pump_systems_enabled === '0'
				) {
					setSkipStepThree(true);
				}
			})
			.catch((err) =>
				customToast({
					variant: Variants.Danger,
					title:
						err?.response?.data?.message ||
						'Something went wrong...',
				})
			);

		axios
			.get<response>(
				route(API_ENDPOINTS.REGISTRATION.STEP_FOUR, {
					registrationId: registrationId || '',
				})
			)
			.then((res) => setValues(res.data.data))
			.catch((err) =>
				customToast({
					variant: Variants.Danger,
					title:
						err?.response?.data?.message ||
						'Something went wrong...',
				})
			);
	}, []);

	useEffect(() => setValues({ username: store.email }), [store.email]);

	useEffect(() => {
		if (!isSuccess) return;

		navigate(`/register/${registrationId}/company-details`);
	}, [isSuccess]);

	const handleGasSafeLookupChange = (data: any) => {
		const gasSafeFirstName = data?.engineer?.first_name;
		const gasSafeLastName = data?.engineer?.last_name;

		const namesMismatch =
			gasSafeFirstName &&
			gasSafeLastName &&
			(store.first_name !== gasSafeFirstName ||
				store.last_name !== gasSafeLastName);


		if (namesMismatch) {
			setValues({
				first_name: gasSafeFirstName,
				last_name: gasSafeLastName,
				gas_safe_confirmation: '0',
			});

			customToast({
				variant: Variants.Warning,
				title: 'Your name has been updated to match the Gas Safe license details.',
			});

			setGasSafeNameMismatch(lang?.gas_safe_desc &&
				'The details on the Gas Safe licence card that you provide will be checked against the registration details that you provide. If those details don’t match, your registration will need to be verified by a platform admin. Please make sure that the name you provide is identical to the name on your licence card.')

		} else {
			setGasSafeNameMismatch('')
		}
	};

	return (
		<AppContainer>
			<ContentContainer
				title={StringHelpers.title(lang?.title || 'User Details')}
			>
				<div className="row row-cols-1 row-cols-lg-2">
					<div className="col">
						{Input({
							name: 'first_name',
							label: StringHelpers.sentence(
								fields.first_name || 'First name'
							),
						})}
					</div>

					<div className="col">
						{Input({
							name: 'last_name',
							label: StringHelpers.sentence(
								fields.last_name || 'Last name'
							),
						})}
					</div>
				</div>

				<div className="row row-cols-1 row-cols-lg-2">
					<div className="col">
						{Input({
							name: 'email',
							label: StringHelpers.sentence(
								fields.email || 'Email'
							),
						})}
					</div>

					<div className="col">
						{Input({
							name: 'username',
							label: StringHelpers.sentence(
								fields.username || 'Username'
							),
							disable: true,
						})}
					</div>
				</div>

				<PhoneNumberField
					name="mobile_phone_number"
					label={StringHelpers.sentence(
						fields.mobile_phone_number || 'Mobile number'
					)}
					onChange={(e) => setValues({ mobile_phone_number: e })}
					value={store.mobile_phone_number}
					errorMessages={
						errors?.mobile_phone_prefix ||
						errors?.mobile_phone_number ||
						null
					}
					prefixValue={store.mobile_phone_prefix}
					prefixOnChange={(e) =>
						setValues({ mobile_phone_prefix: e })
					}
					disabled={disabled}
				/>

				<PhoneNumberField
					name="landline_phone_number"
					label={StringHelpers.sentence(
						fields.landline_phone_number || 'Landline number'
					)}
					onChange={(e) => setValues({ landline_phone_number: e })}
					value={store.landline_phone_number}
					errorMessages={
						errors?.landline_phone_prefix ||
						errors?.landline_phone_number ||
						null
					}
					prefixValue={store.landline_phone_prefix}
					prefixOnChange={(e) =>
						setValues({ landline_phone_prefix: e })
					}
					disabled={disabled}
				/>

				<SubTitle title="Password" />

				<PasswordFieldAndRules
					password={{
						value: store.password || '',
						label: StringHelpers.title(
							fields?.password || 'Password'
						),
						name: 'password',
						onChange: (value: string) =>
							setValues({ password: value }),
						errorMessages:
							!!errors && !!errors.password
								? errors.password
								: null,
					}}
					password_confirmation={{
						value: store.password_confirmation || '',
						label: StringHelpers.title(
							fields?.password_confirmation || 'Confirm password'
						),
						name: 'password_confirmation',
						onChange: (value: string) =>
							setValues({ password_confirmation: value }),
						errorMessages:
							!!errors && !!errors.password_confirmation
								? errors.password_confirmation
								: null,
					}}
					lang={{
						declaration:
							fields.password.declaration ||
							'For a strong password please satisfy the below password requirements.',
						rules: {
							min_char:
								fields.password.min_char ||
								'Minimum 8 characters',
							lower:
								fields.password.lower ||
								'Contains lowercase characters',
							upper:
								fields.password.upper ||
								'Contains uppercase characters',
							numeric:
								fields.password.numeric ||
								'Contains numeric characters',
							symbols:
								fields.password.symbols ||
								'Contains symbols characters',
							confirmed:
								fields.password.confirmed ||
								'Passwords must match',
						},
					}}
				/>

				{Input({
					type: 'select',
					name: 'terms_confirmation',
					label:
						fields.terms_conditions ||
						"I've read and accept the terms and conditions",
					options: crud.options.boolean,
					capitaliseLabel: false,
				})}

				<p className="mb-4">
					<a
						href={termsLink}
						target="_blank"
					>
						{lang?.terms || '(See terms and conditions)'}
					</a>
				</p>

				<PostcodeLookup
					data={store}
					setData={setValues}
					errors={errors}
				/>

				{getThemeFromUrl() === 'viessmann' &&
					hasGasBoiler &&
					store.company_gas_safe_number_skipped === '0' && (
						<div className="mt-3">
							<SubTitle title="Gas safe" />

							{Input({
								type: 'select',
								name: 'gas_safe_licence_card_number_skipped',
								label: StringHelpers.sentence(
									lang?.gas_safe_checkbox ||
									'I am Gas Safe verified'
								),
								options: crud?.options.reverse_boolean,
							})}

							<p>
								{lang?.gas_safe_disc ||
									'Gas Safe details are required to register product installations with Gas Safe and issue building regulation certificates. If your company is not Gas Safe registered, please select "No" in the box above.'}
							</p>

							{store.gas_safe_licence_card_number_skipped ===
								'0' && (
									<GasSafeLookup
										fetchOnLoad
										displayCard
										onGasSafeNumberChange={(v) => {
											setValues({
												gas_safe_licence_card_number: v,
											});
										}}
										onGasSafeConfirmedChange={(v) => {
											setValues({
												gas_safe_confirmation: v
													? '1'
													: '0',
											});
										}}
										disabled={disabled}
										gasSafeNumber={
											store?.gas_safe_licence_card_number
										}
										gasSafeConfirmed={
											store?.gas_safe_confirmation === '1'
										}
										labels={{
											gasSafeNumber:
												fields?.gas_safe_licence_card_number,
											gasSafeConfirmed:
												fields?.gas_safe_confirmation_generic,
										}}
										errors={{
											gasSafeNumber:
												errors?.gas_safe_licence_card_number ||
												'',
											gasSafeConfirmed:
												errors?.gas_safe_confirmation ||
												'',
										}}
										type="engineer"
										warningMessage={gasSafeNameMismatch}
										onGasSafeLookupChange={(data) => {
											handleGasSafeLookupChange(data)
										}
										}
									/>
								)}
						</div>
					)}

				<div className="d-flex justify-content-end justify-content-lg-between mt-5">
					<Button
						label={
							crud?.buttons.back_to_login.default ||
							'Back To Login'
						}
						variant={Variants.Dark}
						type={ButtonTypes.Outline}
						href="/login"
						className="d-none d-lg-inline-block"
						disabled={disabled}
					/>

					<div className="d-flex gap-3">
						<Button
							label={crud?.buttons.previous.default || 'Previous'}
							variant={Variants.Dark}
							type={ButtonTypes.Outline}
							onClick={() =>
								navigate(
									`/register/${registrationId}/${skipStepThree
										? 'system-selection'
										: 'system-specific'
									}`
								)
							}
							disabled={disabled}
						/>
						<Submit
							defaultLabel={crud?.buttons.next.default || 'Next'}
							loadingLabel={
								crud?.buttons.next.submitting || 'Saving...'
							}
							disabled={disabled}
							variant={Variants.Info}
						/>
					</div>
				</div>
			</ContentContainer>
		</AppContainer>
	);
}

export default UserDetails;
