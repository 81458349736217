import axios from 'axios';
import {
	useLangContext,
	useForm,
	route,
	API_ENDPOINTS,
	customToast,
	Variants,
	Button,
	ButtonTypes,
	getThemeFromUrl,
	StringHelpers,
	useDisabledContext,
	SelectableCardInput,
	useNavigationGuard,
	ContentContainer,
	AppContainer,
} from 'carrier-fe';
import ViessmannGas from 'carrier-fe/src/images/viessmann_gas.jpg';
import ViessmannHeatpumps from 'carrier-fe/src/images/viessmann_heatpumps.jpg';
import ViessmannSolar from 'carrier-fe/src/images/viessmann_solar.jpg';
import ViessmannElectric from 'carrier-fe/src/images/viessmann_electric.svg';
import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const defaultData = {
	company_gas_boiler_systems_enabled: '0',
	company_heat_pump_systems_enabled: '0',
	company_solar_systems_enabled: '0',
	company_other_systems_enabled: '0',
};

type response = {
	data: typeof defaultData;
};

function SystemSelection() {
	useNavigationGuard({ message: 'Are you sure' });

	const { registration, crud } = useLangContext();
	const { registrationId } = useParams();
	const { disabled } = useDisabledContext();
	const navigate = useNavigate();

	useNavigationGuard({ message: 'Are you sure' });

	const skipStepThree = useRef(false);

	const lang = registration?.page?.step_two[getThemeFromUrl()] || null;

	const patch = async (data: typeof defaultData) => {
		const res = await axios.patch(
			route(API_ENDPOINTS.REGISTRATION.STEP_TWO, {
				registrationId: registrationId || '',
			}),
			data
		);

		const {
			company_gas_boiler_systems_enabled,
			company_heat_pump_systems_enabled,
			company_solar_systems_enabled,
		} = res.data.data;

		if (
			company_gas_boiler_systems_enabled === false &&
			company_solar_systems_enabled === false &&
			company_heat_pump_systems_enabled === false
		) {
			await axios.patch(
				route(API_ENDPOINTS.REGISTRATION.STEP_THREE, {
					registrationId: registrationId || '',
				})
			);
			skipStepThree.current = true;
		}

		return res;
	};

	const { Submit, setValues, errors, store, isSuccess } = useForm(
		patch,
		defaultData,
		{ silentSuccess: true }
	);
	const hasErrors = Object.keys(errors || {}).length > 0;

	useEffect(() => {
		axios
			.get<response>(
				route(API_ENDPOINTS.REGISTRATION.STEP_TWO, {
					registrationId: registrationId || '',
				})
			)
			.then((res) => setValues(res.data.data))
			.catch((err) =>
				customToast({
					variant: Variants.Danger,
					title:
						err?.response?.data?.message ||
						'Something went wrong...',
				})
			);
	}, []);

	useEffect(() => {
		if (!isSuccess) return;

		if (skipStepThree.current)
			navigate(`/register/${registrationId}/user-details`);
		else navigate(`/register/${registrationId}/system-specific`);
	}, [isSuccess]);

	return (
		<AppContainer>
			<ContentContainer
				title={StringHelpers.title(lang?.title || 'System Selection')}
			>
				<p className="text-center mb-4">
					{StringHelpers.sentence(
						lang?.select_all ||
							'Which technology do you currently install?'
					)}
				</p>

				<div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 gy-4">
					<div className="col">
						<SelectableCardInput
							value={store?.company_gas_boiler_systems_enabled}
							imageSrc={ViessmannGas}
							label={StringHelpers.sentence(
								lang?.gas_boilers || 'Gas Boilers'
							)}
							isSelected={
								store.company_gas_boiler_systems_enabled === '1'
							}
							errorField={hasErrors}
							onChange={(value: any) => {
								setValues({
									company_gas_boiler_systems_enabled:
										value === '0' ? '1' : '0',
								});
							}}
							noMargin
						/>
					</div>
					<div className="col">
						<SelectableCardInput
							value={store?.company_heat_pump_systems_enabled}
							imageSrc={ViessmannHeatpumps}
							label={StringHelpers.sentence(
								lang?.heat_pumps || 'Heat Pumps'
							)}
							isSelected={
								store.company_heat_pump_systems_enabled === '1'
							}
							errorField={hasErrors}
							onChange={(value: any) => {
								setValues({
									company_heat_pump_systems_enabled:
										value === '0' ? '1' : '0',
								});
							}}
							noMargin
						/>
					</div>
					<div className="col">
						<SelectableCardInput
							value={store?.company_solar_systems_enabled}
							imageSrc={ViessmannSolar}
							label={StringHelpers.sentence(
								lang?.solar || 'Solar'
							)}
							isSelected={
								store.company_solar_systems_enabled === '1'
							}
							errorField={hasErrors}
							onChange={(value: any) => {
								setValues({
									company_solar_systems_enabled:
										value === '0' ? '1' : '0',
								});
							}}
							noMargin
						/>
					</div>
					<div className="col">
						<SelectableCardInput
							value={store?.company_other_systems_enabled}
							imageSrc={ViessmannElectric}
							label={StringHelpers.sentence(
								lang?.electric || 'Electric'
							)}
							isSelected={
								store.company_other_systems_enabled === '1'
							}
							errorField={hasErrors}
							onChange={(value: any) => {
								setValues({
									company_other_systems_enabled:
										value === '0' ? '1' : '0',
								});
							}}
							objectFit="contain"
							noMargin
						/>
					</div>
				</div>

				<div className="my-4">
					{Object.values(errors || {}).length > 0 && (
						<i className="text-danger">
							{Object.values(errors as string)[0]}
						</i>
					)}
				</div>

				<div className="d-flex justify-content-end justify-content-lg-between mt-5">
					<Button
						label={
							crud?.buttons.back_to_login.default ||
							'Back To Login'
						}
						variant={Variants.Dark}
						type={ButtonTypes.Outline}
						href="/login"
						className="d-none d-lg-inline-block"
						disabled={disabled}
					/>

					<div className="d-flex gap-3">
						<Button
							label={crud?.buttons.previous.default || 'Previous'}
							variant={Variants.Dark}
							type={ButtonTypes.Outline}
							onClick={() =>
								navigate(`/register/${registrationId}/overview`)
							}
							disabled={disabled}
						/>
						<Submit
							defaultLabel={crud?.buttons.next.default || 'Next'}
							loadingLabel={
								crud?.buttons.next.submitting || 'Saving...'
							}
							disabled={disabled}
							variant={Variants.Info}
						/>
					</div>
				</div>
			</ContentContainer>
		</AppContainer>
	);
}

export default SystemSelection;
