import {
	useForm,
	route,
	API_ENDPOINTS,
	customToast,
	Variants,
	PostcodeLookup,
	useLangContext,
	getThemeFromUrl,
	StringHelpers,
	useDisabledContext,
	Button,
	ButtonTypes,
	useNavigationGuard,
	ContentContainer,
	AppContainer,
} from 'carrier-fe';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const defaultData = {
	company_name: '',
	company_address_id: '',
	company_address_line_1: '',
	company_address_line_2: '',
	company_address_line_3: '',
	company_town_city: '',
	company_state_county: '',
	company_postcode_zipcode: '',
	company_local_authority_code: '',
	company_sub_country_name: '',
	company_country_code_iso_3: '',
	company_fgas_refcom_standard_number: '',
	company_refcom_elite_number: '',
};
type GasSafeData = any;

type response = { data: typeof defaultData };


function CompanyDetails() {
	const { registrationId } = useParams();
	const { registration, crud, fields } = useLangContext();
	const { disabled } = useDisabledContext();
	const navigate = useNavigate();

	const [fieldsDisabled, setFieldsDisabled] = useState(false);
	const [gasSafeData, setGasSafeData] = useState<GasSafeData | null>(null);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [initialValuesSet, setInitialValuesSet] = useState(false);

	useNavigationGuard({ message: 'Are you sure' });

	const lang = registration?.page?.step_five[getThemeFromUrl()] || null;

	const isToshiba = getThemeFromUrl() === 'toshiba';

	const patch = async (formData: typeof defaultData) => {
		let dataToSubmit = formData;

		if (gasSafeData && gasSafeData.name) {
			dataToSubmit = {
				...formData,
				company_name: gasSafeData.name,
				company_address_line_1: gasSafeData.address_line_1 || '',
				company_address_line_2: gasSafeData.address_line_2 || '',
				company_address_line_3: gasSafeData.address_line_3 || '',
				company_town_city: gasSafeData.town_city || '',
				company_state_county: gasSafeData.state_county || '',
				company_postcode_zipcode: gasSafeData.postcode_zipcode || '',
				company_address_id: gasSafeData.address_id || '',
				company_country_code_iso_3: gasSafeData.country_code_iso_3 || 'GBR',
			};
		}

		try {
			const res = await axios.patch(
				route(API_ENDPOINTS.REGISTRATION.STEP_FIVE, {
					registrationId: registrationId || '',
				}),
				dataToSubmit
			);
			try {
				localStorage.removeItem(`registration_${registrationId}_gas_safe_data`);
			} catch (error) {
				throw error;
			}

			return res;
		} catch (error) {
			throw error;
		}
	};


	const { Submit, Input, setValues, errors, store, isSuccess } = useForm(
		patch,
		defaultData,
		{ silentSuccess: true }
	);

	useEffect(() => {
		try {
			const storedData = localStorage.getItem(`registration_${registrationId}_gas_safe_data`);

			if (storedData) {
				const parsedData = JSON.parse(storedData);
				setGasSafeData(parsedData);
			}
		} catch (e) {
			throw e;
		}
	}, [registrationId]);

	useEffect(() => {
		axios
			.get<response>(
				route(API_ENDPOINTS.REGISTRATION.STEP_FIVE, {
					registrationId: registrationId || '',
				})
			)
			.then((res) => {
				if (!gasSafeData) {
					setValues(res.data.data);
				}

				setDataLoaded(true);
			})
			.catch((err) => {
				customToast({
					variant: Variants.Danger,
					title:
						err?.response?.data?.message ||
						'Something went wrong...',
				})
			});
	}, []);

	useEffect(() => {
		if (!initialValuesSet && dataLoaded) {
			if (gasSafeData) {
				const combinedData = {
					company_name: gasSafeData.name || store.company_name,
					company_address_line_1: gasSafeData.address_line_1 || store.company_address_line_1,
					company_address_line_2: gasSafeData.address_line_2 || store.company_address_line_2,
					company_address_line_3: gasSafeData.address_line_3 || store.company_address_line_3,
					company_town_city: gasSafeData.town_city || store.company_town_city,
					company_state_county: gasSafeData.state_county || store.company_state_county,
					company_postcode_zipcode: gasSafeData.postcode_zipcode || store.company_postcode_zipcode,
					company_address_id: gasSafeData.address_id || store.company_address_id,
					company_country_code_iso_3: gasSafeData.country_code_iso_3 || store.company_country_code_iso_3,
				};

				setValues(combinedData);
				setFieldsDisabled(true);
			}

			setInitialValuesSet(true);
		}
	}, [gasSafeData, dataLoaded, store, initialValuesSet]);

	useEffect(() => {
		if (!isSuccess) return;

		navigate(`/register/${registrationId}/complete`);
	}, [isSuccess]);

	const isFieldDisabled = disabled || fieldsDisabled;

	return (
		<AppContainer>
			<ContentContainer
				title={StringHelpers.title(lang?.title || 'Company Details')}
			>
				{gasSafeData && fieldsDisabled && (
					<div className="alert alert-info mb-4">
						<p className="mb-0">
							Company details have been populated from your Gas Safe verification.  {/*ADD LANG*/}
						</p>
					</div>
				)}

				{Input({
					name: 'company_name',
					label: ((gasSafeData && gasSafeData.name) || '') || StringHelpers.sentence(
						fields.company_name || 'Company name'
					),
					disable: gasSafeData ? isFieldDisabled : disabled,
				})}
				{isToshiba &&
					Input({
						name: 'company_fgas_refcom_standard_number',
						label: StringHelpers.sentence(
							fields.fgas_refcom_standard_number ||
							'FGAS / REFCOM standard number'
						),
					})}
				{isToshiba &&
					Input({
						name: 'company_refcom_elite_number',
						label: StringHelpers.sentence(
							fields.refcom_elite_number || 'REFCOM elite number'
						),
					})}
				{!gasSafeData?.name && <PostcodeLookup
					data={store}
					setData={setValues}
					errors={errors}
					prefix="company_"
				/>}

				{gasSafeData?.name &&
					<div className="row">
						<div className="col-12">
							{Input({
								name: 'company_address_line_1',
								label: (gasSafeData && gasSafeData?.address_line_1) || '',
								placeholder: 'Address line 1',
								disable: gasSafeData ? isFieldDisabled : disabled,
							})}
						</div>
						<div className="col-12">
							{Input({
								name: 'company_address_line_2',
								label: (gasSafeData && gasSafeData?.address_line_2) || '',
								placeholder: 'Address line 2',
								disable: gasSafeData ? isFieldDisabled : disabled,
							})}
						</div>
						<div className="col-12">
							{Input({
								name: 'company_address_line_3',
								label: (gasSafeData && gasSafeData?.address_line_3) || '',
								placeholder: 'Address line 3',
								disable: gasSafeData ? isFieldDisabled : disabled,
							})}
						</div>
						<div className="col-12">
							{Input({
								name: 'company_town_city',
								label: (gasSafeData && gasSafeData?.town_city) || '',
								placeholder: 'Town / City',
								disable: gasSafeData ? isFieldDisabled : disabled,
							})}
						</div>
						<div className="col-12 col-md-6">
							{Input({
								name: 'company_state_county',
								label: (gasSafeData && gasSafeData?.state_county) || '',
								placeholder: 'County',
								disable: gasSafeData ? isFieldDisabled : disabled,
							})}
						</div>
						<div className="col-12 col-md-6">
							{Input({
								name: 'company_postcode_zipcode',
								label: (gasSafeData && gasSafeData?.postcode_zipcode) || '',
								placeholder: 'Postcode',
								disable: gasSafeData ? isFieldDisabled : disabled,
							})}
						</div>
					</div>}

				<div className="d-flex justify-content-between mt-5">
					<Button
						label={
							crud?.buttons.back_to_login.default ||
							'Back To Login'
						}
						variant={Variants.Dark}
						type={ButtonTypes.Outline}
						href="/login"
						className="d-none d-lg-inline-block"
						disabled={disabled}
					/>

					<div className="d-flex gap-3">
						<Button
							label={crud?.buttons.previous.default || 'Previous'}
							variant={Variants.Dark}
							type={ButtonTypes.Outline}
							onClick={() =>
								navigate(
									`/register/${registrationId}/user-details`
								)
							}
							disabled={disabled}
						/>
						<Submit
							defaultLabel={crud?.buttons.next.default || 'Next'}
							loadingLabel={
								crud?.buttons.next.submitting || 'Saving...'
							}
							disabled={disabled}
							variant={Variants.Info}
						/>
					</div>
				</div>
			</ContentContainer>
		</AppContainer>
	);
}

export default CompanyDetails;