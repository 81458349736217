import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
	API_ENDPOINTS,
	customToast,
	Variants,
	route,
	WidthConstrainedContainer,
} from 'carrier-fe';
import axios from 'axios';

function Register() {
	const navigate = useNavigate();
	document.title = 'Registration';
	
	useEffect(() => {
		if (window.location.pathname !== '/register') return;

		axios
			.post(route(API_ENDPOINTS.REGISTRATION.CREATE))
			.then((res) => {
				navigate('/register/' + res.data.data.id);
			})
			.catch((err) =>
				customToast({
					variant: Variants.Danger,
					title:
						err?.response?.data?.message ||
						'Something went wrong...',
				})
			);
	}, []);

	return (
		<div
			className={'w-100 d-flex justify-content-center align-items-start bg-light p-3 p-md-4 p-lg-5'}
			style={{
				minHeight: '100vh',
				overflowX: 'hidden'
			}}
		>
			<WidthConstrainedContainer
				maxWidth={1000}
			>
				<Outlet />
			</WidthConstrainedContainer>
		</div>
	)
}

export default Register;
