import axios from 'axios';
import {
	PageLayout,
	getThemeFromUrl,
	PostcodeLookup,
	StringHelpers,
	LangContext,
	route,
	API_ENDPOINTS,
	customToast,
	Variants,
	useForm,
	Button,
	Themes,
	SubTitle,
	WidthConstrainedContainer,
	Card,
	CardBody,
	BottomAnchoredControls,
	FormInput,
	GasSafeLookup,
	useDisabledContext,
	useLangContext,
	Spinner,
	SelectableCardInput,
} from 'carrier-fe';
import { useEffect, useMemo, useState, useContext } from 'react';
import { LangMyCompany } from '../../../types/lang/my-company';
import ViessmannGas from 'carrier-fe/src/images/viessmann_gas.jpg';
import ViessmannHeatpumps from 'carrier-fe/src/images/viessmann_heatpumps.jpg';
import ViessmannSolar from 'carrier-fe/src/images/viessmann_solar.jpg';
import ViessmannElectric from 'carrier-fe/src/images/viessmann_electric.svg';

const defaultData = {
	name: '',
	address_line_1: '',
	address_line_2: '',
	address_line_3: '',
	town_city: '',
	state_county: '',
	postcode_zipcode: '',
	country_code_iso_3: '',
	fgas_refcom_standard_number: '',
	refcom_elite_number: '',
	account_number: '',
	dispatch_note_field_label: '',
	dispatch_note_required: '',
	distributor_claim_type: '',
	distributor_claim_inbox_email: '',
	gas_boiler_systems_enabled: false,
	heat_pump_systems_enabled: false,
	solar_systems_enabled: false,
	other_systems_enabled: false,
	gas_safe_number: '',
	gas_safe_confirmed: false,
	type: '',
	gas_safe_company_name: '',
};

type Data = typeof defaultData;

type Page<T> = {
	page: {
		fields: T;
		submit_button: {
			default: 'Update';
			submitting: 'Updating...';
		};
		title: 'My Company';
		sections: {
			details: 'Details';
			additional: 'Additional';
			installer: 'Installer';
			distributor: 'Distributor';
		};
		download_company_warranty_certificate: 'Download Company Warranty Certificate';
	};
};

function MyCompany() {
	const [isFetching, setIsFetching] = useState(false);
	const { getLangPage } = useContext(LangContext);
	const { fields, registration } = useLangContext();
	const [lang, setLang] = useState<Page<LangMyCompany>>();
	const theme = useMemo(() => getThemeFromUrl(), []);
	const [claimType, setClaimType] = useState([]);
	const { disabled } = useDisabledContext();
	const [gasSafeNameMismatch, setGasSafeNameMismatch] = useState('');

	const registrationLang =
		registration?.page?.step_four[getThemeFromUrl()] || null;

	const read = async () => {
		if (isFetching) return;
		setIsFetching(true);

		try {
			const res = await axios.get<{ data: Data }>(
				route(API_ENDPOINTS.GENERIC.MY_COMPANY.EDIT)
			);

			if (!res || !res.data) return;
			setValues(res.data.data);
		} catch (error: any) {
			if (!!error && !!error.response && !!error.response.data) {
				if (!!error.response.data.message) {
					customToast({
						title: error.response.data.message,
						variant: Variants.Danger,
					});
				}
			} else {
				console.log(error);
				customToast({
					title: 'An error occurred. Please refresh the page to try again.',
					variant: Variants.Danger,
				});
			}
		} finally {
			setIsFetching(false);
		}
	};

	const update = (payload: typeof defaultData) => {
		if (
			payload.gas_safe_number &&
			payload.gas_safe_company_name &&
			payload.name !== payload.gas_safe_company_name
		) {
			payload.name = payload.gas_safe_company_name;
			payload.gas_safe_confirmed = false;

			customToast({
				variant: Variants.Warning,
				title: 'Your company name has been updated to match the Gas Safe number details.',
			});

			setGasSafeNameMismatch(
				registrationLang?.gas_safe_desc ||
					'The details on the Gas Safe licence card that you provide will be checked against the registration details that you provide. If those details don’t match, your registration will need to be verified by a platform admin. Please make sure that the name you provide is identical to the name on your licence card.'
			);

			setValues({
				name: payload.gas_safe_company_name,
				gas_safe_confirmed: false,
			});

			return Promise.reject(
				new Error('Please confirm Gas Safe details before updating.')
			);
		}
		const { dispatch_note_required, ...rest } = payload;

		const updatedPayload =
			dispatch_note_required === 'yes'
				? { ...rest, dispatch_note_required: true }
				: { ...rest, dispatch_note_required: false };

		return axios.patch<{ data: Data; message: string }>(
			route(API_ENDPOINTS.GENERIC.MY_COMPANY.UPDATE),
			updatedPayload
		);
	};

	const { Input, Checkbox, Submit, errors, store, setValues } = useForm(
		update,
		defaultData
	);

	const handleGasSafeLookupChange = (data: any) => {
		const gasSafeCompanyName = data?.company?.name;

		const nameMismatch =
			gasSafeCompanyName && store.name !== gasSafeCompanyName;

		if (nameMismatch) {
			setValues({
				name: gasSafeCompanyName,
				gas_safe_company_name: gasSafeCompanyName,
				gas_safe_confirmed: false,
			});

			customToast({
				variant: Variants.Warning,
				title: 'Your company name has been updated to match the Gas Safe number details.',
			});

			setGasSafeNameMismatch(
				registrationLang?.gas_safe_desc ||
					'The details on the Gas Safe licence card that you provide will be checked against the registration details that you provide. If those details don’t match, your registration will need to be verified by a platform admin. Please make sure that the name you provide is identical to the name on your licence card.'
			);
		} else {
			setGasSafeNameMismatch('');
		}
	};

	const hasSystemError = useMemo(() => {
		for (let k of [
			'gas_boiler_systems_enabled',
			'heat_pump_systems_enabled',
			'solar_systems_enabled',
			'other_systems_enabled',
		]) {
			if (errors && errors[k]) return true;
		}

		return false;
	}, [errors]);

	const viewable = (props: {
		themes?: typeof theme | (typeof theme)[];
		type?: 'distributor' | 'installer';
	}) => {
		const { themes, type } = props;

		if (themes && ![themes].flat().includes(theme)) {
			return false;
		}

		if (type && store.type !== type) {
			return false;
		}

		return true;
	};

	const toshibaInstaller = viewable({
		themes: Themes.Toshiba,
		type: 'installer',
	});

	const toshibaDistributor = viewable({
		themes: Themes.Toshiba,
		type: 'distributor',
	});

	const vokeraViessmann = viewable({
		themes: [Themes.Vokera, Themes.Viessmann],
	});

	useEffect(() => {
		read();
		getLangPage('dashboard/my_company').then((res: Page<LangMyCompany>) =>
			setLang(res)
		);
	}, []);

	useEffect(() => {
		if (toshibaDistributor) {
			axios
				.get(
					route(API_ENDPOINTS.GENERIC.SELECT.DISTRIBUTOR_CLAIM_TYPES)
				)
				.then((res) => setClaimType(res.data.data))
				.catch((err) => {
					console.error(err);
				});
		}
	}, [toshibaDistributor]);

	return (
		<PageLayout
			title={StringHelpers.title(lang?.page.title || 'My Company')}
		>
			<WidthConstrainedContainer>
				{isFetching ? (
					<div className="d-flex justify-content-center align-items-center vh-100">
						<Spinner />
					</div>
				) : (
					<>
						<Card>
							<CardBody>
								<SubTitle
									title={StringHelpers.title(
										lang?.page.sections.details || 'Details'
									)}
									className="mb-3 w-100"
									style={{ color: '#464C5E' }}
								/>
								{Input({
									name: 'name',
									label: lang?.page.fields.name || 'Name',
								})}
								{toshibaInstaller && (
									<div
										className={
											'd-flex justify-content-end align-items-center'
										}
									>
										<Button
											label={
												lang?.page
													.download_company_warranty_certificate ||
												'Download Company Warranty Certificate'
											}
											variant={Variants.Info}
										/>
									</div>
								)}
							</CardBody>
						</Card>
						<Card>
							<CardBody>
								<PostcodeLookup
									data={store}
									setData={setValues}
									errors={errors}
								/>
							</CardBody>
						</Card>
						{toshibaInstaller && (
							<Card>
								<CardBody>
									<SubTitle
										title={StringHelpers.title(
											lang?.page.sections.installer ||
												'Installer'
										)}
										className="mb-3 w-100"
										style={{ color: '#464C5E' }}
									/>
									{Input({
										name: 'fgas_refcom_standard_number',
										label:
											lang?.page.fields
												.fgas_refcom_standard_number ||
											'FGAS / REFCOM standard number',
										visible: toshibaInstaller,
									})}
									{Input({
										name: 'refcom_elite_number',
										label:
											lang?.page.fields
												.refcom_elite_number ||
											'REFCOM elite number',
										visible: toshibaInstaller,
									})}
									{Input({
										name: 'account_number',
										label:
											lang?.page.fields.account_number ||
											'Account number',
										visible: toshibaInstaller,
									})}
								</CardBody>
							</Card>
						)}
						{toshibaDistributor && (
							<Card>
								<CardBody>
									<SubTitle
										title={StringHelpers.title(
											lang?.page.sections.distributor ||
												'Distributor'
										)}
										className="mb-3 w-100"
										style={{ color: '#464C5E' }}
									/>
									{Input({
										name: 'dispatch_note_field_label',
										label:
											lang?.page.fields
												.dispatch_note_field_label ||
											'Dispatch note field label',
										type: 'textarea',
										visible: toshibaDistributor,
									})}
									{Input({
										name: 'dispatch_note_required',
										label:
											lang?.page.fields
												.dispatch_note_required ||
											'Dispatch note required',
										type: 'select',
										options: [
											{
												label: 'Yes',
												value: 'yes',
											},
											{
												label: 'No',
												value: 'no',
											},
										],
										visible: toshibaDistributor,
									})}
									{Input({
										type: 'select',
										name: 'distributor_claim_type',
										label:
											lang?.page.fields
												.distributor_claim_type ||
											'Distributor claim type',
										visible: toshibaDistributor,
										options: claimType,
									})}
									{Input({
										name: 'distributor_claim_inbox_email',
										label:
											lang?.page.fields
												.distributor_claim_inbox_email ||
											'Distributor claims inbox email',
										visible: toshibaDistributor,
									})}
								</CardBody>
							</Card>
						)}
						{vokeraViessmann && (
							<Card>
								<CardBody>
									<SubTitle
										title={StringHelpers.title(
											lang?.page.sections.installer ||
												'Installer'
										)}
										className="mb-3 w-100"
										style={{ color: '#464C5E' }}
									/>
									<div className="row row-cols-1 row-cols-md-2 row-cols-lg-4">
										<div className="col">
											<SelectableCardInput
												value={
													store?.gas_boiler_systems_enabled
												}
												imageSrc={ViessmannGas}
												label={StringHelpers.title(
													fields?.company_gas_boiler_systems_enabled
												)}
												errorField={hasSystemError}
												isSelected={
													store?.gas_boiler_systems_enabled
												}
												onChange={(value: any) => {
													setValues({
														gas_boiler_systems_enabled:
															!value,
													});
												}}
											/>
										</div>
										<div className="col">
											<SelectableCardInput
												value={
													store?.heat_pump_systems_enabled
												}
												imageSrc={ViessmannHeatpumps}
												label={StringHelpers.title(
													fields?.company_heat_pump_systems_enabled
												)}
												errorField={hasSystemError}
												isSelected={
													store?.heat_pump_systems_enabled
												}
												onChange={(value: any) => {
													setValues({
														heat_pump_systems_enabled:
															!value,
													});
												}}
											/>
										</div>
										<div className="col">
											<SelectableCardInput
												value={
													store?.solar_systems_enabled
												}
												imageSrc={ViessmannSolar}
												label={StringHelpers.title(
													fields?.company_solar_systems_enabled
												)}
												isSelected={
													store?.solar_systems_enabled
												}
												errorField={hasSystemError}
												onChange={(value: any) => {
													setValues({
														solar_systems_enabled:
															!value,
													});
												}}
											/>
										</div>
										<div className="col">
											<SelectableCardInput
												value={
													store?.other_systems_enabled
												}
												imageSrc={ViessmannElectric}
												label={StringHelpers.title(
													fields?.company_other_systems_enabled
												)}
												errorField={hasSystemError}
												isSelected={
													store?.other_systems_enabled
												}
												onChange={(value: any) => {
													setValues({
														other_systems_enabled:
															!value,
													});
												}}
											/>
										</div>
									</div>
									<GasSafeLookup
										type="company"
										fetchOnLoad
										disabled={disabled}
										displayCard
										gasSafeNumber={
											store?.gas_safe_number || ''
										}
										gasSafeConfirmed={
											store?.gas_safe_confirmed
										}
										onGasSafeNumberChange={(value) =>
											setValues({
												gas_safe_number: value,
											})
										}
										onGasSafeConfirmedChange={(value) =>
											setValues({
												gas_safe_confirmed: value,
											})
										}
										errors={{
											gasSafeNumber:
												errors?.gas_safe_number,
											gasSafeConfirmed:
												errors?.gas_safe_confirmed,
										}}
										labels={{
											gasSafeNumber:
												fields?.gas_safe_number,
											gasSafeConfirmed:
												fields?.gas_safe_confirmation_company,
										}}
										warningMessage={gasSafeNameMismatch}
										onGasSafeLookupChange={
											handleGasSafeLookupChange
										}
									/>
								</CardBody>
							</Card>
						)}
					</>
				)}
			</WidthConstrainedContainer>
			<BottomAnchoredControls>
				<Submit
					defaultLabel={lang?.page.submit_button.default || 'Upload'}
					loadingLabel={
						lang?.page.submit_button.submitting || 'Uploading'
					}
				/>
			</BottomAnchoredControls>
		</PageLayout>
	);
}

export default MyCompany;
