import {
	Button,
	getThemeFromUrl,
	Variants,
	useLangContext,
	StringHelpers,
} from 'carrier-fe';
import SuccessIcon from './icons';

function Completion() {
	const { registration, crud } = useLangContext();

	const lang = registration?.page?.complete[getThemeFromUrl()] || null;

	return (
		<div className="d-flex justify-content-center align-items-center vh-100">
			<div className="text-center">
				<SuccessIcon />
				<h1>
					{StringHelpers.title(
						lang?.title || 'Registration Complete'
					)}
				</h1>
				<p className="w-75 mx-auto mb-4">{lang?.article}</p>
				<Button
					label={
						crud?.buttons.back_to_login.default || 'Back To Login'
					}
					href="/login"
					variant={Variants.Info}
				/>
			</div>
		</div>
	);
}

export default Completion;
