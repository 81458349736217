import axios from 'axios';
import {
	API_ENDPOINTS,
	Button,
	ButtonTypes,
	customToast,
	getThemeFromUrl,
	route,
	StringHelpers,
	useDisabledContext,
	useForm,
	useLangContext,
	Variants,
	useNavigationGuard,
	GasSafeLookup,
	ContentContainer,
	AppContainer,
} from 'carrier-fe';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

type SystemSelectionType = {
	company_gas_boiler_systems_enabled: string;
	company_heat_pump_systems_enabled: string;
	company_solar_systems_enabled: string;
	company_other_systems_enabled: string;
};

type SystemSpecificType = {
	company_gas_safe_number: string;
	company_gas_safe_confirmed: string;
	company_mcs_number: string;
	company_mcs_number_confirmation: string;
	company_gas_safe_number_skipped: string;
};

// type GasSafeData = {
// 	gas_safe_number: number
// 	name: string
// 	address_line_1: string
// 	address_line_2: string
// 	address_line_3: any
// 	town_city: string
// 	state_county: string
// 	postcode_zipcode: string
// 	address_id: any
// 	country_code_iso_3: string
// }

type GasSafeData = any;


const defaultData: SystemSpecificType = {
	company_gas_safe_number: '',
	company_gas_safe_confirmed: '0',
	company_mcs_number: '',
	company_mcs_number_confirmation: '0',
	company_gas_safe_number_skipped: '0',
};

function SystemSpecific() {
	const { registration, crud, fields } = useLangContext();
	const { registrationId } = useParams();
	const { disabled } = useDisabledContext();
	const navigate = useNavigate();

	useNavigationGuard({ message: 'Are you sure' });

	const lang = registration?.page?.step_three[getThemeFromUrl()] || null;

	const [systemSelection, setSystemSelection] = useState<SystemSelectionType>(
		{
			company_gas_boiler_systems_enabled: '0',
			company_heat_pump_systems_enabled: '0',
			company_solar_systems_enabled: '0',
			company_other_systems_enabled: '0',
		}
	);

	const [gasSafeCompanyData, setGasSafeCompanyData] = useState<GasSafeData | null>(null)

	const patch = async (data: SystemSpecificType) => {
		data.company_mcs_number_confirmation =
			data.company_mcs_number_confirmation === 'yes' ? '1' : '0';

		if (gasSafeCompanyData && data.company_gas_safe_confirmed === '1') {
			localStorage.setItem(
				`registration_${registrationId}_gas_safe_data`,
				JSON.stringify(gasSafeCompanyData)
			);
		} else {
			localStorage.removeItem(`registration_${registrationId}_gas_safe_data`);
		}

		const res = await axios.patch(
			route(API_ENDPOINTS.REGISTRATION.STEP_THREE, {
				registrationId: registrationId || '',
			}),
			data
		);

		return res;
	};

	const { Submit, setValues, store, isSuccess, Input, errors } = useForm(
		patch,
		defaultData,
		{ silentSuccess: true }
	);

	useEffect(() => {
		axios
			.get(
				route(API_ENDPOINTS.REGISTRATION.STEP_TWO, {
					registrationId: registrationId || '',
				})
			)
			.then((res) => setSystemSelection(res.data.data))
			.catch((err) =>
				customToast({
					variant: Variants.Danger,
					title:
						err?.response?.data?.message ||
						'Something went wrong...',
				})
			);
	}, []);

	useEffect(() => {
		axios
			.get(
				route(API_ENDPOINTS.REGISTRATION.STEP_THREE, {
					registrationId: registrationId || '',
				})
			)
			.then((res) => setValues(res.data.data))
			.catch((err) =>
				customToast({
					variant: Variants.Danger,
					title:
						err?.response?.data?.message ||
						'Something went wrong...',
				})
			);
	}, []);

	useEffect(() => {
		if (!isSuccess) return;

		navigate(`/register/${registrationId}/user-details`);
	}, [isSuccess]);

	const handleGasSafeLookupChange = (data: any) => {
		if (data.company) {
			setGasSafeCompanyData(data.company);
		} else {
			;
			setGasSafeCompanyData(null);
		}
	};

	return (
		<AppContainer>
			<ContentContainer
				title={StringHelpers.title(
					registration?.title || 'Company Verification'
				)}
			>
				{systemSelection.company_gas_boiler_systems_enabled === '1' && (
					<>
						{Input({
							type: 'select',
							name: 'company_gas_safe_number_skipped',
							label: StringHelpers.sentence(
								lang?.gas_safe_checkbox ||
								'My company is Gas Safe verified'
							),
							options: crud?.options.reverse_boolean,
						})}
						<p>
							{lang?.gas_safe_disc ||
								'Gas Safe details are required to register product installations with Gas Safe and issue building regulation certificates. If your company is not Gas Safe registered, please select "No" in the box above.'}
						</p>

						{store.company_gas_safe_number_skipped === '0' && (
							<>
								<p>
									{StringHelpers.sentence(
										lang?.gas_safe_instruction ||
										'Please enter your company Gas Safe Registration Number'
									)}
								</p>

								<GasSafeLookup
									type="company"
									displayCard
									fetchOnLoad
									gasSafeNumber={
										store.company_gas_safe_number
									}
									gasSafeConfirmed={
										store.company_gas_safe_confirmed === '1'
									}
									onGasSafeNumberChange={(value) =>
										setValues({
											company_gas_safe_number: value,
										})
									}
									onGasSafeConfirmedChange={(value) =>
										setValues({
											company_gas_safe_confirmed: value
												? '1'
												: '0',
										})
									}
									labels={{
										gasSafeNumber: fields?.gas_safe_number,
										gasSafeConfirmed:
											fields?.gas_safe_confirmation_generic,
									}}
									errors={{
										gasSafeNumber:
											errors?.company_gas_safe_number,
										gasSafeConfirmed:
											errors?.company_gas_safe_confirmed,
									}}
									disabled={disabled}
									onGasSafeLookupChange={handleGasSafeLookupChange}
								/>
							</>
						)}
					</>
				)}

				{(systemSelection.company_solar_systems_enabled === '1' ||
					systemSelection.company_heat_pump_systems_enabled ===
					'1') && (
						<>
							<p>
								{StringHelpers.sentence(
									lang?.mcs_instruction ||
									'Please enter your MCS number'
								)}
							</p>
							{Input({
								name: 'company_mcs_number',
								label: StringHelpers.sentence(
									fields?.company_mcs_number || 'MCS Number'
								),
							})}
							<p>
								{StringHelpers.sentence(
									lang?.mcs_skip_next ||
									'If you are not MCS accredited, please click next'
								)}
							</p>
						</>
					)}

				<div className="d-flex justify-content-end justify-content-lg-between mt-5">
					<Button
						label={
							crud?.buttons.back_to_login.default ||
							'Back To Login'
						}
						variant={Variants.Dark}
						type={ButtonTypes.Outline}
						href="/login"
						className="d-none d-lg-inline-block"
						disabled={disabled}
					/>

					<div className="d-flex gap-3">
						<Button
							label={crud?.buttons.previous.default || 'Previous'}
							variant={Variants.Dark}
							type={ButtonTypes.Outline}
							onClick={() =>
								navigate(
									`/register/${registrationId}/system-selection`
								)
							}
							disabled={disabled}
						/>
						<Submit
							defaultLabel={crud?.buttons.next.default || 'Next'}
							loadingLabel={
								crud?.buttons.next.submitting || 'Saving...'
							}
							disabled={disabled}
							variant={Variants.Info}
						/>
					</div>
				</div>
			</ContentContainer>
		</AppContainer>
	);
}

export default SystemSpecific;
