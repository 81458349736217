import axios from 'axios';
import {
	API_ENDPOINTS,
	Button,
	customToast,
	getThemeFromUrl,
	route,
	StringHelpers,
	useDisabledContext,
	useLangContext,
	Variants,
	ButtonTypes,
	ContentContainer,
	checkTheme,
	Themes,
	Card,
	CardBody,
	AppContainer,
	wrapEmailWithMailto,
} from 'carrier-fe';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function Overview() {
	const { registration, crud } = useLangContext();
	const { registrationId } = useParams();

	const [isSubmitting, setIsSubmitting] = useState(false);
	const { disabled } = useDisabledContext();

	const navigate = useNavigate();

	const lang = registration?.page?.step_one[getThemeFromUrl()];

	let nextStepPath = 'system-selection';

	if (checkTheme(Themes.Toshiba)) {
		nextStepPath = 'user-details';
	}

	useEffect(() => {
		if (!isSubmitting) return;
		nextStep();
	}, [isSubmitting]);

	const nextStep = () => {
		axios
			.patch(
				route(API_ENDPOINTS.REGISTRATION.STEP_ONE, {
					registrationId: registrationId || '',
				})
			)
			.then(() => navigate(`/register/${registrationId}/${nextStepPath}`))
			.catch((err) =>
				customToast({
					variant: Variants.Danger,
					title:
						err?.response?.data?.message ||
						'Something went wrong...',
				})
			)
			.finally(() => setIsSubmitting(false));
	};

	return (
		<AppContainer>
			<ContentContainer
				title={StringHelpers.title(lang?.title || 'Register')}
			>
				<p className="text-center mb-4">{lang?.description}</p>

				<div className="row row-cols-12 row-cols-lg-2 mb-4">
					{lang?.systems?.map(
						(system: { label: string; tooltip: string[] }) => (
							<div
								className="col mb-3"
								key={system.label}
							>
								<Card
									className="h-100"
									noMargin
								>
									<CardBody>
										<strong>{system.label}</strong>
										<ul className="mt-2">
											{system.tooltip.map(
												(sentence, index) => (
													<li
														key={index}
														className="text-muted"
													>
														{sentence}
													</li>
												)
											)}
										</ul>
									</CardBody>
								</Card>
							</div>
						)
					)}
				</div>

				{lang?.update_notice && (
					<p className="text-center">
						{wrapEmailWithMailto(lang?.update_notice)}
					</p>
				)}

				<div className="d-flex justify-content-between mt-5">
					<Button
						label={
							crud?.buttons.back_to_login.default ||
							'Back To Login'
						}
						variant={Variants.Dark}
						type={ButtonTypes.Outline}
						href="/login"
						disabled={disabled}
					/>
					<Button
						label={
							isSubmitting
								? crud?.buttons.next.submitting || 'Saving...'
								: crud?.buttons.next.default || 'Next'
						}
						onClick={() => setIsSubmitting(true)}
						disabled={isSubmitting || disabled}
						variant={Variants.Info}
					/>
				</div>
			</ContentContainer>
		</AppContainer>
	);
}

export default Overview;
